// Particle based confetti effect:

import * as THREE from "three";
import { GameObject } from "../../core/GameObject";
import { camera, gui, scene } from "../../core/setup";


export class SmokeLayer extends GameObject {

  constructor() {
    super();

    const plane = new THREE.PlaneGeometry(1,1);
    const material = new THREE.ShaderMaterial({
      uniforms: {
        uRocketPosition : {value: new THREE.Vector2(0,0)},
        uTime: {value: 0},
        uResolution: {value: new THREE.Vector2(512, 512)},
        uTexture: {value: smokeTexture.texture}
      },
      vertexShader,
      fragmentShader,
      depthWrite: false,
      blending: THREE.AdditiveBlending,
      vertexColors: true
    });

    const smokeInteractionBuffer = new THREE.WebGLRenderTarget(512, 512, {
      minFilter: THREE.NearestFilter,
      magFilter: THREE.NearestFilter,
      format: THREE.RGBAFormat,
      type: THREE.FloatType
    });

    const mesh = new THREE.Mesh(plane, material);
    mesh.onBeforeRender = (renderer: THREE.WebGLRenderer) => {
      camera.layers.set(1);
      scene.background = new THREE.Color(0xffffff);
      renderer.setRenderTarget(smokeInteractionBuffer);
      renderer.clear();  
      renderer.render(scene, camera)
      smokeTexture.uniforms.uSmokeMask.value = smokeInteractionBuffer.texture;
      renderer.setRenderTarget(null);
      smokeTexture.render();
      camera.layers.set(0);
      scene.background = new THREE.Color(0x000000);

    }

    this.add(mesh);
  }

  onUpdate(dt: number): void {
    // X and Y positions need to be set in screen-space 0-1 coords,
    // so we need to project it to the camera first:
    const rocket = Rocket.getRocket();

    const screenPos = rocket.position.clone();
    screenPos.project(camera);
    screenPos.x = (screenPos.x + 1) / 2;
    screenPos.y = (screenPos.y + 1) / 2;
    rocketPosition.x = screenPos.x;
    rocketPosition.y = screenPos.y;

    rocketPosition.z = Math.PI-rocket.rotation.z;
    rocketPosition.w = rocket.thrust ? 1 : 0;
  }



}

// Vertex Shader for confetti moves points in an explosion following time:
const vertexShader = `
  uniform float time;
  varying vec3 vColor;
  varying vec2 vUv;

void main()
  {
    vUv = uv;
    gl_Position = vec4(position.x * 2.0, position.y * 2.0, 1, 1);
  }
  `;

const fragmentShader = `
  varying vec3 vColor;
  varying vec2 vUv;
  uniform sampler2D uTexture;
  uniform vec2 uResolution;
  void main() {
    gl_FragColor = textureLod(uTexture, vUv, 1.);
    gl_FragColor.a = 1.0;
  }
  `;

  
import { ShadertoyTexture } from "./ShadertoyTexture";

import imageFrag from "./shaders/image.frag";
import simulation from "./shaders/simulation.frag";
import bufferA from "./shaders/bufferA.frag";
import bufferD from "./shaders/bufferD.frag";
import { Rocket } from "../Rocket/Rocket";

const smokeTexture = new ShadertoyTexture(512, 512);
smokeTexture.common = simulation;

// FLUID SIMULATION:
smokeTexture.bufferA.shader = bufferA;
smokeTexture.bufferA.iChannel0 = smokeTexture.bufferC;
smokeTexture.bufferB.shader = bufferA;
smokeTexture.bufferB.iChannel0 = smokeTexture.bufferA;
smokeTexture.bufferC.shader = bufferA;
smokeTexture.bufferC.iChannel0 = smokeTexture.bufferB;

// COLORING:
smokeTexture.bufferD.shader = bufferD;
smokeTexture.bufferD.iChannel0 = smokeTexture.bufferA;
smokeTexture.bufferD.iChannel1 = smokeTexture.bufferD;

// OUTPUT:
smokeTexture.image.shader = imageFrag;
smokeTexture.image.iChannel0 = smokeTexture.bufferD;

// ROCKET POSITION:
const rocketPosition = new THREE.Vector4(.5,.5, Math.PI, 0);
smokeTexture.uniforms.rocketPosition = {value: rocketPosition};
smokeTexture.uniforms.planets = {value: []};

smokeTexture.uniforms.uSmokeMask = {value: new THREE.Texture()};


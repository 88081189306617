import { Level } from "./Level";

type Instructions = {
  onAccept: () => void;
}

export const showInstructions = (level: Level) => {
  const buttonId = Math.random().toString(36).substring(7);
  const html = `
  <h1>${level.title}</h1>
  <p>${level.description}</p>
  <button id="${buttonId}">
    Got it!
  </button>`
  const div = document.createElement('div');
  div.id = "instructions";
  div.innerHTML = html;
  document.body.appendChild(div);
  const button = document.getElementById(buttonId);

  const handler = {
    onAccept: () => {}
  };

  button!.onclick = (ev : Event) => {
    document.getElementById('instructions')!.remove();
    handler.onAccept();
    ev.preventDefault();
  }

  return handler
}

export const showSuccess = (level : Level) => {
  const buttonId = Math.random().toString(36).substring(7);

  const html = `
  <h1>Success!</h1>
  <p>You beat the level in ${Math.round(level.clock.getElapsedTime())} seconds!</p>
  <button id="${buttonId}">
    Next Level!
  </button>`
  const div = document.createElement('div');
  div.id = "instructions";
  div.innerHTML = html;
  document.body.appendChild(div);
  const button = document.getElementById(buttonId);

  const handler = {
    onAccept: () => level.start()
  };

  button!.onclick = (ev : Event) => {
    document.getElementById('instructions')!.remove();
    handler.onAccept();
    ev.preventDefault();
  }


  return handler
}

export const showFailure = (level : Level, message = "") => {
  const buttonId = Math.random().toString(36).substring(7);

  const html = `
  <h1>Failure...</h1>
  <p>${message}</p>
  <button id="${buttonId}">
    Try Again!
  </button>`
  const div = document.createElement('div');
  div.id = "instructions";
  div.innerHTML = html;
  document.body.appendChild(div);
  const button = document.getElementById(buttonId);

  const handler = {
    onAccept: () => level.restart()
  };

  button!.onclick = (ev : Event) => {
    document.getElementById('instructions')!.remove();
    handler.onAccept();
    ev.preventDefault();
  }


  return handler
}


export const showPause = (level : Level, message = "") => {
  const buttonId = Math.random().toString(36).substring(7);

  const html = `
  <h1>Paused</h1>
  <p>${message}</p>
  <button id="${buttonId}">
    Start
  </button>`
  const div = document.createElement('div');
  div.id = "instructions";
  div.innerHTML = html;
  document.body.appendChild(div);
  const button = document.getElementById(buttonId);

  const handler = {
    onAccept: () => level.unpause()
  };

  button!.onclick = (ev : Event) => {
    document.getElementById('instructions')!.remove();
    handler.onAccept();
    ev.preventDefault();
  }


  return handler
}
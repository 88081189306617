import * as THREE from 'three';
import { ConcreteMixin, MixedGameObjectContstructor, MixinList } from '../objects/Mixin';
import { EventMap, GameEvent } from './Events';
import { Coordinates } from './Coordinates';

export type GameObjectEvent = {
  type: string,
  object: GameObject
};
  
export class GameObject extends THREE.Group{
  /** 2D Coordinates of GameObject - is automatically converted to Vector3 */
  coordinates = new Coordinates();

  /** Alias for `coordinates`: */
  get coords () {
    return this.coordinates;
  };
  set coords (coords: Coordinates) {
    this.coordinates = coords;
  };


  classList: string[] = [];
  velocity: THREE.Vector2 = new THREE.Vector2();

  static with<K extends MixinList>(...mixins : K) : MixedGameObjectContstructor<K>{
    class MixedGameObject extends GameObject {
      updateList : ((dt:number) => void)[] = [];

      constructor(...args: any[]){
        super();
        for(const mixin of mixins){
          const instance = new mixin();
          this.updateList.push(instance.onUpdate);
          // Assign all properties of the mixin to this object, except what is already defined:
          for(const key of Object.getOwnPropertyNames( Object.getPrototypeOf(instance) )) {
            if(this[key] === undefined){
              this[key] = instance[key];
            }
          }
        }
      }

      onUpdate(dt: number){
        this.updateList.forEach(update => update.apply(this, [dt]));
      }
    }
    return MixedGameObject as any as MixedGameObjectContstructor<K>;
  }

  constructor(){
    super();

    this.coordinates.onChange((coords) => {
      this.position.set(coords.x, coords.offset, coords.y);
    });
    this.addEventListener('removed', (event) => {
      console.log("GameObj removed");
      if(event.object) return;
      // If we are the removed object, let all children know they've been removed as well
      this.traverse(child => {
        child.dispatchEvent({type: "removed", object: this})
      });
    });

    this.addEventListener('added', (event) => {
      if(event.object) return;
      // If we are the added object, let all children know they've been removed as well
      this.traverse(child => {
        child.dispatchEvent({type: "added", object: this})
      });
    });
  }

  on<T extends keyof EventMap>(type: T, handler: (e: EventMap[T]) => void): void {
    this.addEventListener(type, (e) => {
      handler(e as unknown as EventMap[T]);
    });
  }

  fire<T extends keyof EventMap>(type: T, event: Omit<EventMap[T], "type">) : void {
    const ev = event as EventMap[T];
    ev.type = type;
    this.dispatchEvent(ev);
    if(this.parent){
      this.parent.dispatchEvent(ev);
    }
  }

  onUpdate(dt: number){

  }
}


import * as THREE from 'three';

let stars : THREE.Points;


export const loadBackground = () => {
  const background = new THREE.Group();

  // Background Plane:
  const planeGeometry = new THREE.PlaneGeometry(30, 30)
  const planeMaterial = new THREE.MeshPhongMaterial({ color: 0x111115 });

  // Material blending is additive:
  planeMaterial.blending = THREE.AdditiveBlending;
  planeMaterial.transparent = true;

  const plane = new THREE.Mesh(planeGeometry, planeMaterial);
  plane.rotation.x = -Math.PI / 2
  plane.receiveShadow = true;
  background.add(plane);

  // Hemisphere Light:
  const ambientLight = new THREE.HemisphereLight(0xaa8855, 0x001133, .8)
  ambientLight.position.set(0, 1, -2)
  background.add(ambientLight)

  // Star Particles
  const starGeometry = new THREE.BufferGeometry();
  const starSize = Math.log10(window.innerWidth) / 2;
  const starMaterial = new THREE.PointsMaterial({ color: 0xffffff, size: starSize, sizeAttenuation: false })
  const starCount = 4000;
  const starPositions = new Float32Array(starCount * 3);
  for (let i = 0; i < starCount * 3; i+= 3) {
    starPositions[i] = (Math.random() - 0.5) * 30;
    starPositions[i+1] = -2;
    starPositions[i + 2] = (Math.random() - 0.5) * 30;
  }

  starGeometry.setAttribute('position', new THREE.BufferAttribute(starPositions, 3))
  stars = new THREE.Points(starGeometry, starMaterial)
  background.add(stars)

  return background;
};



export const getStars = () => stars;

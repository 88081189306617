import * as THREE from 'three';
import { gui, onTick } from '../core/setup';
import { Rocket } from './Rocket/Rocket';
import { GameObject } from '../core/GameObject';

const geometry = new THREE.IcosahedronGeometry(1, 4)

export class Sun extends GameObject {
  velocity = new THREE.Vector2();

  constructor(size : number = 1) {
    super();

    // Test Sun:
    const sunGeometry = new THREE.IcosahedronGeometry(0.5, 4)
    const sunMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff })
    const sun = new THREE.Mesh(sunGeometry, sunMaterial)
    sun.position.set(0, -1, 0)

    this.add(sun)

    // Sun light:
    const sunLight = new THREE.PointLight(0xffa06c, 5, 500)
    sunLight.position.set(0, 2, 0)
    sunLight.castShadow = true;
    this.add(sunLight);
    // sun.add(sunLight)
    // gui.add(sunLight, 'intensity', 0, 10).name('Sun Intensity')
    // gui.add(sunLight, 'distance', 0, 300).name('Sun Distance')

  }
  
  // Suns move in parallax a bit:
  update(dt){
    const rocket = Rocket.getRocket();
    this.position.x = 3 + rocket.position.x * .2;
    this.position.z = 2 + rocket.position.z * .2;
  };
  
}
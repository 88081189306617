import * as THREE from 'three';
import { Rocket } from '../objects/Rocket/Rocket';
import { camera, onTick, renderer, scene } from '../core/setup';
import { loadBackground } from '../objects/Stars';
import { GameObject } from '../core/GameObject';
import { beforeRender } from '../core/Camera';
import { showFailure, showInstructions, showPause, showSuccess } from './messages';
import { Planet } from '../objects/Planet';
import { LandingRating } from '../objects/Rocket/LandingRating';
import { EventMap, LandEvent } from '../core/Events';

// Level both loads level and kinda runs the game?
// This will have to be cleaned up later into a Game.ts and a Level.ts
export class Level extends THREE.Group {
  private static currentLevel : Level;
  private static currentLevelLoader : (level: Level) => any;
  private rating = new LandingRating();

  static getCurrentLevel() {
    return Level.currentLevel;
  }

  static load(levelLoader : (level: Level) => any) {
    if(Level.currentLevel) {
      console.log("Removing old level...");
      Level.currentLevel.clear();
      scene.remove(Level.currentLevel);
    }
    Level.currentLevelLoader = levelLoader;
    const level = new Level();
    level.rocket.reset();
    level.add(loadBackground());
    levelLoader(level);
    scene.add(level);
    renderer.compile(scene, camera);
    return level;
  }

  getObjectsByClass(className: string) {
    return this.children.filter((obj) => {
      return obj instanceof GameObject && obj.classList.includes(className);
    }) as GameObject[];
  }

  getPlanets() {
    return this.getObjectsByClass("planet") as Planet[];
  }

  restart() {
    this.loaded = false;
    this.clock.stop();
    const level = Level.load(Level.currentLevelLoader);
    level.start();
  }

  loaded = false;
  rocket = Rocket.getRocket();

  finished = false;

  title = "Level";
  description = "No description";
  clock = new THREE.Clock();
  onTick = (dt: number) => {};

  constructor() {
    super();

    this.visible = false;

    // Rocket:
    this.add(this.rocket);
    this.add(this.rating);
    this.rating.position.y = 3;

    this.on("land", (ev) => {
      this.rating.position.copy(ev.object.position);
      this.rating.animate(2);
    });
  }


  on<T extends keyof EventMap>(type: T, handler: (e: EventMap[T]) => void): void {
    this.addEventListener(type, (e) => {
      handler(e as unknown as EventMap[T]);
    });
  }

  fire<T extends keyof EventMap>(type: T, event: Omit<EventMap[T], "type">) : void {
    const ev = event as EventMap[T];
    ev.type = type;
    this.dispatchEvent(ev);
    if(this.parent){
      this.parent.dispatchEvent(ev);
    }
  }
  
  start() {
    Level.currentLevel = this;
    this.loaded = true;
    this.visible = true;
    this.clock.start();
    showInstructions(this);
    
    onTick(dt => {
      if(!this.loaded || this.finished) return;
      if(!this.clock.running) return;
      let gameObjects : GameObject[] = [];
      this.traverse((obj) => {
        if(obj instanceof GameObject) {
          gameObjects.push(obj);
        }
      });
      gameObjects.forEach(obj => obj.onUpdate(dt));

      beforeRender();

      this.onTick(dt)}
    )
  }

  finish() {
    setTimeout(() => {
      // Wait just a split second before showing success message:
      if(this.finished) return;
      this.finished = true;
      this.clock.stop();
      showSuccess(this);
    }, 300);
  }

  pause(message = "") {
    this.clock.stop();
    showPause(this, message);
  }

  unpause() {
    this.clock.start();
  }

  fail(message = "") {
    this.clock.stop();
    if(this.finished) return;
    showFailure(this, message);
    this.finished = true;
  }
}


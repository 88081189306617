import * as THREE from "three";
import { Planet } from "../objects/Planet";
import { Sun } from "../objects/Sun";
import { onTick } from "../core/setup";
import { Level } from "./Level";
import { Rocket } from "../objects/Rocket/Rocket";
import { Fuel } from "../objects/Fuel";
import { LandingPlatform } from "../objects/LandingPlatform";
import { Confetti } from "../objects/Confetti/Confetti";
// In level on you have to start on one side of the earth, and
// land on the other side - indicated by two platforms.

export const levelOne = (level: Level) => {
  level.title = "Tutorial";
  level.description = `
    <li>Press 'W' to thrust forward</li>
    <li>Press 'A' and 'D' to rotate</li>
    <li>To land, go in a straight line and slow down</li><br>
    <b>Can you land on the south pole?</b>`;

  // Earth:
  const earth = new Planet("earth.jpg", 1.3);
  earth.coords.set(-1, 2)
  earth.rotation.x += 0.34;
  level.add(earth);

  earth.on("land", () => {
    level.pause("Close, but not quite, keep going and get on the south pole!")
  })

  // North Platform: 
  const northPole = new LandingPlatform();
  northPole.coords.set(-1, .12);
  level.add(northPole);

  northPole.on("land", (ev) => {
    level.pause("You landed back on the north pole! Try again on the south.");
  });

  // South Platform: 
  const southPole = new LandingPlatform("South Pole");
  southPole.coords.set(-1, 3.86);
  southPole.rotation.y += Math.PI;
  level.add(southPole);

  southPole.on("land", () => {
    confetti.shoot();
    level.finish();
  });

  // Place rocket:
  level.rocket.coords.set(-1, -.3);
  level.rocket.landed = earth;

  level.onTick = (dt) => {
    earth.rotation.z -= dt * 0.1;
  }

  // Sun:
  const sun = new Sun();
  sun.coords.set(4, 2, -1)
  level.add(sun)

  // Confetti!
  const confetti = new Confetti();
  level.add(confetti);
  confetti.coords.set(1, -1, 1);
};
import * as THREE from 'three';
import { GameObject } from "../core/GameObject";
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { camera } from '../core/setup';

export class Label extends GameObject {
  opacity = 1;
  set text(text: string) {
    this.labelObject.element.textContent = text;
  }
  get text() {
    return this.labelObject.element.textContent ?? "";
  }

  hideWhenNear = false;
  lockToScreen = false;
  color = new THREE.Color("white")
  labelObject = new CSS2DObject(document.createElement("div"));

  constructor(text: string, lockToScreen: boolean = true) { 
    super();

    this.labelObject.element.className = 'label';
    this.labelObject.element.style.color = this.color.getStyle();
    this.labelObject.element.textContent = "";
    this.text = text;

    this.add(this.labelObject);

    this.addEventListener('removed', (event) => {
      console.log("REMOVED");
      this.labelObject.removeFromParent();
    });

    this.addEventListener('added', (event) => {
      this.add(this.labelObject)
    });
  }

  onUpdate(dt: number) {
    this.labelObject.visible = this.visible;
    this.labelObject.element.style.opacity = this.opacity.toString();
    // Update the label position so that it always appears on screen:

    const labelPosition = this.getWorldPosition(new THREE.Vector3())
    const projection = labelPosition.project(camera);

    if(this.hideWhenNear) {
      const distance = THREE.MathUtils.clamp((projection.length() - 1) * 5,0,1);
      this.opacity = distance;
    }
    if(this.lockToScreen) {
      // TODO - make it so that the label scales with distance from camera
      // Since we're using an orthographic top-down camera, we can use MathUtils.clamp to keep the label on screen:
      projection.x = THREE.MathUtils.clamp(projection.x, -.90, .90);
      projection.y = THREE.MathUtils.clamp(projection.y, -.95, .95);
      projection.z = THREE.MathUtils.clamp(projection.z, -.95, .95);
      projection.unproject(camera);
      // Projection is world space, so we need to convert it to local space of the parent:
      projection.sub(this.parent?.getWorldPosition(new THREE.Vector3()) ?? new THREE.Vector3());
      
      this.labelObject.position.copy(projection);
    }


  }
}
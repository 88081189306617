/// <reference types="vite-plugin-glsl/ext" />.

import { Resources } from "./core/Resources";
import { setupControls } from "./core/controls";
import { Level } from "./levels/Level";
import { levelOne } from "./levels/level_one";

// This actually does nothing, but it's here to make sure that the
// import is not removed by the compiler, because I'm too lazy to
// structure my code properly.

Resources.loadAll().then(() => {
  setupControls();
  const level = Level.load(levelOne);
  level.start();
});
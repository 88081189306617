import { GameObject } from "../../core/GameObject";
import { Resources } from "../../core/Resources";
import * as THREE from 'three';
import { gui } from "../../core/setup";
import gsap from 'gsap';

const texture = Resources.add("ratings.png");

export class LandingRating extends GameObject {
  sprite: THREE.Sprite;
  
  constructor() {
    super();
    this.classList.push("landing-rating");
    const map = texture.get();

    map.offset.set(0, 0);
    map.repeat.set(1, .1428);
    map.wrapS = THREE.RepeatWrapping;

    // Alpha blending:
    const material = new THREE.SpriteMaterial( { map } );
    material.depthTest = false;
    material.depthWrite = false;
    material.blending = THREE.AdditiveBlending;
    material.opacity = 0;
    this.sprite = new THREE.Sprite( material );
    this.sprite.scale.y = .14;
    this.scale.setScalar(2);
    this.add( this.sprite );

    gui.add(this, "animate").name("Animate");
  }

  /** Shows rating, 0 worst, 6 best. */
  animate(rating: number) {
    this.sprite.position.z = 0;
    this.sprite.material.opacity = 1;
    const map = texture.get();
    map.offset.y = 4 * .1428;
    gsap.to(this.sprite.position, { z: -.3, duration: 1.2 });
    gsap.to(this.sprite.material, { opacity: 0, duration: 1, delay: .2 });
  }
}
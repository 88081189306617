import * as THREE from 'three';
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { camera, gui, onTick, renderer, scene } from '../../core/setup';
import { Planet } from '../Planet';
import { GameObject } from '../../core/GameObject';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { Level } from '../../levels/Level';
import { CrashEvent } from '../mixins/Crashable';
import { Landable } from '../mixins/Landable';
import { Explosion } from '../Explosion';
import { Resources } from '../../core/Resources';
import { LandingRating } from './LandingRating';
import { SmokeLayer } from '../SmokeLayer/SmokeLayer';


const rocketDebug = {
  showGravity: false,
}
const option = gui.add(rocketDebug, 'showGravity').name('Show Gravity');
if(!gui._hidden) option.setValue(true);

// Load this.obj:
const loader = new OBJLoader();

const rocket = Resources.add("rocket.obj", (object) => {
  const rocketObj = (object.children[1] as THREE.Mesh);
  rocketObj.material = new THREE.MeshPhongMaterial({ color: 0x999999 })
  // Remove the weird plane that comes with it:
  object.children[0].removeFromParent();
  object.scale.set(0.08, 0.065, 0.08);
  // Puts the center of mass in a good place
  object.position.y -= 0.3;
  return object;
});

let rocketInstance: Rocket;
const fuelMeter = document.getElementById('fuel-bar') as HTMLDivElement;

export class Rocket extends GameObject {
  static MAX_LANDING_SPEED = 0.8;
  static MAX_LANDING_ANGLE = Math.PI / 12;

  explosion = new Explosion();
  debugArrows : THREE.ArrowHelper[] = [];
  rocket = new THREE.Group();

  static getRocket() {
    if (!rocketInstance) rocketInstance = new Rocket();
    return rocketInstance;
  }

  // Provides a list of world-space points that can be used for collision detection.
  getCollisionPoints() {
    const bottom = new THREE.Vector3(0, -0.3, 0);
    const top = new THREE.Vector3(0, 0.5, 0);
    const left = new THREE.Vector3(-0.2, -0, 0);
    const right = new THREE.Vector3(0.2, -0, 0);
    return [bottom, top,left,right].map(p => this.localToWorld(p));
  }

  // Returns the world-space position of the rocket lander (bottom):
  getLanderPosition() {
    const bottom = new THREE.Vector3(0, -0.3, 0);
    return this.localToWorld(bottom);
  }

  reset()  {
    this.coords.set(0, 0);
    this.rotation.set(0, 0, 0);
    this.rotation.x = -Math.PI / 2;
    this.rocket.visible = true;
    this.inertia.velocity.set(0, 0);
    this.inertia.angularVelocity = 0;
    this.landed = undefined;

    this.fuel = 100;
  }

  fuel = 100;

  // While rocket is "landed" it will not be affected by gravity.
  landed: Landable | undefined;

  thrust = false;
  thrustLight = new THREE.SpotLight(0xff501c, 0, 3)

  rotateLeft = false;
  leftLight = new THREE.SpotLight(0xff501c, 2)

  rotateRight = false;
  rightLight = new THREE.SpotLight(0xff501c, 2)

  inertia = {
    velocity: new THREE.Vector2(0, 0),
    angularVelocity: 0
  };

  constructor() {
    super();
    if (rocketInstance) throw new Error("Rocket already exists! (Rocket is a singleton)");
    rocketInstance = this;
    this.coords.offset = .1;
    this.rotation.x = -Math.PI / 2;

    const smokeLayer = new SmokeLayer();
    smokeLayer.position.set(0, 3, 0);
    this.add(smokeLayer);
  
    // Add lights for when rocket is thrusting:
    this.thrustLight.position.set(0, -.2, .5)
    this.thrustLight.angle = Math.PI / 3;
    this.thrustLight.penumbra = .8;
    this.add(this.thrustLight.target);
    this.thrustLight.target.position.set(0, -4, -2);
    this.add(this.thrustLight);

    this.leftLight.position.set(-.14, .3, .4)
    this.add(this.leftLight.target);
    this.leftLight.penumbra = .8;
    this.leftLight.target.position.set(-2, 0, -2);
    this.add(this.leftLight);

    this.rightLight.position.set(.14, .3, .4)
    this.add(this.rightLight.target);
    this.rightLight.penumbra = .8;
    this.rightLight.target.position.set(2, 0, -2);
    this.add(this.rightLight);

    this.add(this.explosion);
    this.add(this.rocket);
    gui.add(this, "explode").name("Explode");

    this.rocket.add(rocket.get());

    // Add optional transformControls to rocket for debug:
    const transformControls = new TransformControls(camera, renderer.domElement);
    transformControls.attach(this);
    transformControls.enabled = false;
    transformControls.visible = false;
    scene.add(transformControls);

    gui.add({x:false}, 'x').name('Drag Rocket').onChange((v) => {
      transformControls.enabled = v;
      transformControls.visible = v;
    });

    this.on('crash', (e) => {
      // Angle of explosion is the opposite of our velocity:
      const direction = this.inertia.velocity.clone();
      // rotate direction by out rotation:
      direction.rotateAround(new THREE.Vector2(), this.rotation.z).negate();
      this.explosion.explode(direction);
      this.rocket.visible = false;
      Level.getCurrentLevel().fail("Crashed into " + e.object.name);
    });
  }

  onUpdate(dt) {

    if (this.landed) {
      this.inertia.velocity.set(this.landed.velocity.x, -this.landed.velocity.y);
      this.inertia.angularVelocity = 0;
    }

    this.handleThrust(dt);
    this.handleGravity(dt);
  }

  explode = () => {
    this.explosion.explode();
    this.rocket.visible = false;
  }

  handleGravity(dt) {
    if(this.landed) return;

    const gravityVectors = Level.getCurrentLevel().getPlanets().map(planet => {
      // If rocket is close enough to the planet, hide the label:
      const offset = planet.coords.clone().sub(this.coords);
      // const offset = new THREE.Vector2(-(this.position.x - planet.position.x), this.position.z - planet.position.z);
      // Gravity starts at the planet surface, so subtract radius from offset:
      offset.setLength(offset.length() - (planet.radius - .2));

      // Gravity strength starts off as planet gravity:
      let strength = planet.gravity;

      // And is decreased by the distance from the planet:
      strength *= 1 / (offset.length() * .7 + 1.5);

      return offset.setLength(strength);
    });


    const gravity = gravityVectors.reduce((a, b) => a.add(b), new THREE.Vector2(0, 0));

    if (rocketDebug.showGravity) {
      this.debugArrows.forEach(arrow => scene.remove(arrow));
      this.debugArrows = [];
      gravityVectors.forEach(gravity => {
        const invisibleAt = .05;
        if(gravity.length() < invisibleAt) return;
        const opacity = (gravity.length() - invisibleAt) * 5;
        const vec3 = new THREE.Vector3(gravity.x, 0, gravity.y);
        const arrowHelperPosition = this.position.clone();
        arrowHelperPosition.y = 2;
        const arrowHelper = new THREE.ArrowHelper(vec3.normalize(), arrowHelperPosition, gravity.length() * 2, 0xffffff, .1, .1);
        const material = (arrowHelper.line.material as THREE.Material);
        material.opacity = opacity;
        material.transparent = true;
        arrowHelper.cone.material = material;
        scene.add(arrowHelper);
        this.debugArrows.push(arrowHelper);
      });
      // Show total gravity too:
      const vec3 = new THREE.Vector3(gravity.x, 0, gravity.y);
      const arrowHelperPosition = this.position.clone();
      arrowHelperPosition.y = 2;
      const arrowHelper = new THREE.ArrowHelper(vec3.normalize(), arrowHelperPosition, gravity.length() * 2, 0xff0000, .1 ,.1);
      scene.add(arrowHelper);
      this.debugArrows.push(arrowHelper);
    }

    this.inertia.velocity.add(gravity.multiplyScalar(dt));
  }

  handleThrust(dt : number) {
    if (this.fuel) {
      this.thrustLight.intensity = this.thrust ? 10 + Math.random() * 2: 0;
      this.leftLight.intensity = this.rotateRight ? 2 + Math.random() : 0;
      this.rightLight.intensity = this.rotateLeft ? 2 + Math.random() : 0;

      // Apply thrust.
      if (this.thrust) {
        this.fuel -= 5 * dt;
        this.landed = undefined;
        const thrust = new THREE.Vector2(0, -1);
        thrust.rotateAround(new THREE.Vector2(0, 0), -this.rotation.z);

        this.inertia.velocity.add(thrust.multiplyScalar(dt * .8));
      }

      // Apply rotation.
      if (this.rotateLeft) {
        this.fuel -= 1 * dt;
        this.inertia.angularVelocity += .8 * dt;
      }
      if (this.rotateRight) {
        this.fuel -= 1 * dt;
        this.inertia.angularVelocity -= .8 * dt;
      }

      const fuelColor = new THREE.Color(0x26ff00);
      fuelColor.lerpHSL(new THREE.Color(0xff0000), 1 - this.fuel / 100);
      fuelMeter.style.width = `${this.fuel}%`;
      fuelMeter.style.backgroundColor = fuelColor.getStyle();
    } else {
      this.thrustLight.intensity = 0;
      this.leftLight.intensity = 0;
      this.rightLight.intensity = 0;

      fuelMeter.style.width = `100%`;
      fuelMeter.style.backgroundColor = '#000000';
      fuelMeter.style.color = "white";
      fuelMeter.textContent = 'Out of fuel!';
    }


    // Clamp fuel:
    this.fuel = Math.max(0, Math.min(100, this.fuel));
    // Apply inertia.
    this.coords.x += this.inertia.velocity.x * dt;
    this.coords.y += this.inertia.velocity.y * dt;
    this.rotation.z += this.inertia.angularVelocity * dt;
  }
}

import { Level } from "../../levels/Level";
import * as THREE from "three";
import { Mixin } from "../Mixin";
import { Rocket } from "../Rocket/Rocket";

export type LandingArea = {
  containsPoint: (point: THREE.Vector3) => boolean;
};

export class Landable extends Mixin {
  static class = "landable";

  checkLandingAngle(landerPosition: THREE.Vector3, rocketAngle : number) {
    // Default case for round objects:
    const offset = this.position.clone().sub(landerPosition);
    const approachAngle = (Math.atan2(offset.z, offset.x) + (Math.PI * 2)) % (Math.PI * 2);
    // Get the difference between the two angles, accounting for the fact that the angle can wrap around
    // EG 0 and 2PI are the same angle, so the difference between them is 0, not 2PI
    const angleDiff = Math.min(Math.abs(rocketAngle - approachAngle), Math.abs(rocketAngle - approachAngle + (Math.PI * 2)), Math.abs(rocketAngle - approachAngle - (Math.PI * 2)));
    // And determine if the rocket angle is within the landing angle:
    return angleDiff < Rocket.MAX_LANDING_ANGLE;
  }

  checkLandingPosition(landerPosition: THREE.Vector3) {
    return this.getLandingArea().containsPoint(landerPosition)
  }

  getLandingArea() : LandingArea {
    return new THREE.Box3().setFromObject(this);
  }

  onUpdate(dt: number) {
    // Check if Level and Rocket are ready:
    if(!Level.getCurrentLevel()) return;
    const rocket = Rocket.getRocket();
    if (rocket.landed) return;
  
    // Check if the rocket is close enough to the object:
    const landerPosition = rocket.getLanderPosition();
    if(!this.checkLandingPosition(landerPosition)) return;

    // Check relative speed:
    const relativeSpeed = rocket.inertia.velocity.clone();
    relativeSpeed.y *= -1;
    relativeSpeed.sub(this.velocity)
    const distance = new THREE.Vector2(this.position.x - landerPosition.x, this.position.z - landerPosition.z);
    const speedTowardsPlanet = -(relativeSpeed.dot(distance.clone().normalize()));  
    if(speedTowardsPlanet > Rocket.MAX_LANDING_SPEED || speedTowardsPlanet < 0) return;

    // Check angle:
    const rocketAngle = (-rocket.rotation.z + (Math.PI * 4.5)) % (Math.PI * 2);
    if(this.checkLandingAngle(landerPosition, rocketAngle)) {
      rocket.landed = this;
      console.log(speedTowardsPlanet);
      this.fire("land", {object: this});
    }
  };
};

import { Level } from "../../levels/Level";
import { Mixin } from "../Mixin";
import * as THREE from "three";
import { Rocket } from "../Rocket/Rocket";
import { GameEvent } from "../../core/Events";

export type CollisionArea = {
  containsPoint: (point: THREE.Vector3) => boolean;
};

export interface CrashEvent extends GameEvent {
  type: "crash";
  object: Crashable;
}

export class Crashable extends Mixin {
  static class = "crashable";

  getCrashArea() : CollisionArea{
    return new THREE.Box3().setFromObject(this);
  }

  onUpdate(dt: number) {
    // Check if Level and Rocket are ready:
    if(!Level.getCurrentLevel()) return;
    const rocket = Rocket.getRocket();
    if (rocket.landed) return;

    if(this.checkCrash(rocket)) {
      // rocket.crashed = this;
      rocket.fire("crash", {object: this});
      this.onCrash();
    }
  }

  checkCrash(rocket: Rocket) {
    const crashArea = this.getCrashArea();
    const collisionPoints = rocket.getCollisionPoints();

    return collisionPoints.some(point =>  crashArea.containsPoint(point));
  }
  
  onCrash() {
    console.log("Crashed!");
  }
};

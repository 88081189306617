// KEYBOARD CONTROLS:
// While "W" is down, the rocket will gain thrust.

import { Rocket } from "../objects/Rocket/Rocket";

// While "A" or "D" is down, the rocket will rotate.
function handleKey(up: boolean, key: string) {
  const rocket = Rocket.getRocket();
  switch (key) {
    case "w":
      rocket.thrust = up;
      break;
    case "a":
      rocket.rotateLeft = up;
      break;
    case "d":
      rocket.rotateRight = up;
      break;
  }
}


window.addEventListener("keydown", (event) => {
  handleKey(true, event.key);
});

window.addEventListener("keyup", (event) => {
  handleKey(false, event.key);
});

// PHONE CONTROLS:

// Screen is divided into 3 zones, left, middle and right.
// Left and right zones are used to rotate the rocket.
// Middle zone is used to thrust the rocket.
const canvas = document.getElementsByTagName("canvas")[0] as HTMLCanvasElement;

canvas.addEventListener("touchstart", (event) => {
  const rocket = Rocket.getRocket();
  event.preventDefault();
  const zoneWidth = window.innerWidth / 3;

  [...event.touches].forEach((touch) => {
    const x = touch.clientX;

    if (x < zoneWidth) {
      rocket.rotateRight = true;
    } else if (x > zoneWidth * 2) {
      rocket.rotateLeft = true;
    } else {
      rocket.thrust = true;
    }
  });
});

canvas.addEventListener("touchend", (event) => {
  const rocket = Rocket.getRocket();
  event.preventDefault();

  const zoneWidth = window.innerWidth / 3;

  rocket.rotateRight = false;
  rocket.rotateLeft = false;
  rocket.thrust = false;

  [...event.touches].forEach((touch) => {
    const x = touch.clientX;

    if (x < zoneWidth) {
      rocket.rotateRight = true;
    } else if (x > zoneWidth * 2) {
      rocket.rotateLeft = true;
    } else {
      rocket.thrust = true;
    }
  });
});

// Disable context menu when long tapping:
canvas.addEventListener("contextmenu", (event) => {
  event.preventDefault();
  return false;
});


export const setupControls = () => {};

// Particle based confetti effect:

import * as THREE from "three";
import { GameObject } from "../core/GameObject";
import { gui } from "../core/setup";


export class Explosion extends GameObject {
  sparkCount = 3000;
  spread = 600;
  animating = false;

  light = new THREE.PointLight(0xff5500, 1, 100);

  constructor() {
    super();
    this.classList.push("explosion");

    const geometry = new THREE.BufferGeometry();

    const colors = new Float32Array(this.sparkCount * 3);
    const vertices = new Float32Array(this.sparkCount * 3);

    for (let i = 0; i < this.sparkCount * 3; i+= 3) {
      vertices[i] = (Math.random() - .5);
      vertices[i+1] = (Math.random() - .5);
      vertices[i + 2] = Math.random();
      
      colors[i] = Math.random();
      colors[i+1] = Math.random();
      colors[i+2] = Math.random();
    }
    geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3))

    const particles = new THREE.Points(geometry, explosionMaterial);

    this.add(particles);
    this.add(this.light);

  };

  explode(direction = new THREE.Vector2(0, 0)) {
    if(this.animating) return;
    explosionMaterial.visible = true;
    console.log(direction);

    explosionMaterial.uniforms.time.value = 0;
    explosionMaterial.uniforms.direction.value = direction;
    this.light.intensity = 6;
    this.animating = true;
    const animate = () => {
      if(this.light.intensity > 0) {
        explosionMaterial.uniforms.time.value += 0.08;
        this.light.intensity -= .05;
        requestAnimationFrame(animate);
      } else {
        this.light.intensity = 0;
        explosionMaterial.visible = false;
        this.animating = false;
      }
    }
    animate();
  }
}

// Vertex Shader for confetti moves points in an explosion following time:
const vertexShader = `
  uniform float time;
  uniform vec2 direction;

  void main() {
      vec3 pos = position;
      vec3 color = color;
      float t = sqrt(time);
      // Move towards direction:
      pos.x += direction.x * t * 0.5;
      pos.y += direction.y * t * 0.5;

      pos.x *= t * pos.z * 1.5;
      pos.y *= t * pos.z * 1.5;

      pos.z += sin(t) * 0.1;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
      gl_PointSize = 4.0 - t;
    }
`;

const fragmentShader = `
  uniform float time;

  void main() {
    gl_FragColor =  vec4(1.0, 0.2, 0.0, 1.0);
  }
  `;

  const explosionMaterial = new THREE.ShaderMaterial({
    uniforms: {
      time: { value: 0.0 },
      direction: {value: new THREE.Vector2(0, 0)}
    },
    vertexShader,
    fragmentShader,
    visible: false,
    depthWrite: false,
    blending: THREE.AdditiveBlending,
    vertexColors: true
  });
import { GameObject } from "../core/GameObject";
import { Crashable } from "./mixins/Crashable";
import { Landable } from "./Rocket/Landable";

export type ConcreteMixin = {
  new(...args: any[]): GameObject;
  class: string;
}

export type MixinList = ConcreteMixin[];

type UnionToIntersection<U> = (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;

// MixedGameObject intersects all the mixins together into a single intersection type:
export type MixedGameObject<T extends MixinList> = GameObject & UnionToIntersection<{
  [P in keyof T]: T[P] extends ConcreteMixin ? InstanceType<T[P]> : never;
}[number]>


export type MixedGameObjectContstructor<T extends MixinList> = {
  new(...args: any[]): MixedGameObject<T>;
}

export abstract class Mixin extends GameObject{
  static class: string;
}

import { Mixin } from "../Mixin";

export const SMOKE_LAYER = 1;

export class SmokeInteraction extends Mixin {
  static class = "smoke-interaction";

  constructor() {
    super();
    this.layers.enable(SMOKE_LAYER);
  }

  onUpdate(dt: number): void {
    if(!this.smokeObject) return;
    this.smokeObject.layers.enable(SMOKE_LAYER);
  }

  smokeObject : THREE.Object3D | undefined;
}
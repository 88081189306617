import * as THREE from 'three';
import { onTick } from '../core/setup';
import { Rocket } from './Rocket/Rocket';
import { GameObject } from '../core/GameObject';
import { Label } from './Label';
import { Crashable } from './mixins/Crashable';
import { Landable } from './mixins/Landable';
import { SmokeInteraction } from './mixins/SmokeInteraction';

const geometry = new THREE.IcosahedronGeometry(1, 5)


export class Planet extends GameObject.with(Landable, Crashable, SmokeInteraction) {
  gravity = 1;
  radius = 1;
  velocity = new THREE.Vector2();
  label: Label = new Label("");

  orbitTarget : Planet | undefined;
  orbitDistance = 0;
  orbitSpeed = 0;

  constructor(map : string, size : number = 1) {
    super();
    this.classList.push("planet");

    this.gravity = size;
    this.radius = size * 1.5;

    const material = new THREE.MeshPhongMaterial({ color: 0xffffff, map: new THREE.TextureLoader().load(map)})
    const planet = new THREE.Mesh(geometry, material);
    this.smokeObject = planet;
    planet.scale.set(this.radius, this.radius, this.radius);
    planet.rotation.x = (-Math.PI / 2);

    planet.castShadow = true;
    this.add(planet);

    if(this.name) {
      this.label = new Label(this.name, false);
      this.label.position.z = 0;
      this.label.position.x = 0;
      this.add(this.label);
    }
  }

  getLandingArea() {
    return {
      containsPoint: (point: THREE.Vector3) => {
        const distance = point.distanceTo(this.position);
        //console.log(distance);
        return distance < this.radius;
      }
    }
  }

  getCrashArea() {
    return this.getLandingArea();
  }

  orbitAround(planet: Planet, speed? : number) {
    this.orbitDistance = this.position.distanceTo(planet.position);

    if(!speed) speed = 3 * planet.gravity / this.orbitDistance;
    this.orbitTarget = planet;
    this.orbitSpeed = speed;
  }

  updateOrbitPosition(dt){
    if(this.orbitTarget === undefined) return;
    const position = new THREE.Vector2(this.position.x, this.position.z);
    const orbitPosition = new THREE.Vector2(this.orbitTarget.position.x, this.orbitTarget.position.z);
    const orbitDirection = position.sub(orbitPosition).normalize().rotateAround(new THREE.Vector2(0, 0), Math.PI / 2).setLength(this.orbitSpeed);

    this.velocity.x = orbitDirection.x;
    this.velocity.y = orbitDirection.y;
  }

  onUpdate(dt) {
    super.onUpdate(dt);
    if(this.orbitTarget) this.updateOrbitPosition(dt);

    if(this.label) this.label.text = this.name;

    const positionChange = this.velocity.clone().multiplyScalar(dt);
    this.position.x += positionChange.x;
    this.position.z += positionChange.y;
  };
}
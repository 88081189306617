import * as THREE from 'three'
import * as dat from 'lil-gui'
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { Resources } from './Resources';
import Stats from 'stats.js'

/**
 * Basic Setup Stuff:
 */

// Debug
export const gui = new dat.GUI()
gui.hide();

const stats = new Stats()


// Canvas
const canvas = document.querySelector('canvas.webgl') as HTMLCanvasElement;

// Scene
export const scene = new THREE.Scene()

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight
}

let cameraScale = 5;
cameraScale = 5 + (sizes.width / 500);

let aspectRatio = sizes.height / sizes.width;

function resize() {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight
    aspectRatio = sizes.height / sizes.width;
  
    cameraScale = 10 - aspectRatio * 4;
    // Update camera
    camera.top = -5 + cameraScale * aspectRatio * 2;
    camera.bottom = -5;
    camera.left = -cameraScale;
    camera.right = cameraScale;
  
    camera.updateProjectionMatrix()
  
    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    labelRenderer.setSize(sizes.width, sizes.height)
}
window.addEventListener('resize', resize)

/**
 * Camera
 */
export const camera = new THREE.OrthographicCamera( -cameraScale, cameraScale, cameraScale * aspectRatio, cameraScale * -aspectRatio, -2, 100);
camera.position.set(0, 8, 0)
camera.lookAt(new THREE.Vector3())
scene.add(camera);

/**
 * Renderers
 */
const pixelRatio = Math.min(window.devicePixelRatio, 1)
export const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: pixelRatio == 1,
});

renderer.shadowMap.enabled = true;

//  renderer.toneMapping = THREE.CineonToneMapping;
//  renderer.toneMappingExposure = .4;
//  renderer.outputEncoding = THREE.sRGBEncoding;
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(pixelRatio)

const labelRenderer = new CSS2DRenderer();
labelRenderer.setSize(sizes.width, sizes.height)
labelRenderer.domElement.style.position = 'absolute';
labelRenderer.domElement.style.overflow = "none";
labelRenderer.domElement.style.top = '0px';
labelRenderer.domElement.style.pointerEvents = 'none';
document.body.appendChild( labelRenderer.domElement );



/**
 * Animate
 */
const clock = new THREE.Clock()

const tickers : Function[] = [];

export const onTick = (callback: (elapsedTime: number) => void) => {
  tickers.push(callback);
}
renderer.debug.checkShaderErrors = false;

// If ?debug or 'dev' is in the url, show the debug menu.
if (window.location.search.includes('debug') || window.location.toString().includes('dev')) {
  gui.show();
  renderer.debug.checkShaderErrors = true;
  stats.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom
  document.body.appendChild(stats.dom)
}

resize();

const tick = () => {
  stats.begin()

  const dt = clock.getDelta();
  // console.log(dt);

  tickers.forEach(ticker => ticker(dt))
  // Render
  renderer.render(scene, camera)
  labelRenderer.render(scene, camera)

  // Call tick again on the next frame
  stats.end()

  window.requestAnimationFrame(tick)
}

Resources.loadAll().then(tick);

scene.background = new THREE.Color(0x555555);
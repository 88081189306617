import * as THREE from 'three';
import { renderer } from '../../core/setup';

type UniformMap = {
  [uniform: string]: THREE.IUniform<any>;
};

const BASE_FRAG = `
precision highp float;
uniform vec2 iResolution;
uniform float iTime;
uniform int iFrame;
uniform vec4 iMouse;

varying vec2 vUv;
uniform sampler2D iChannel0;
uniform sampler2D iChannel1;

void mainImage( out vec4,  vec2 fragCoord );
void main () {
    vec4 outfrag;
    mainImage(outfrag,iResolution*vUv);
    gl_FragColor = outfrag;
}
`;

const VERTEX_SHADER = `
    varying vec2 vUv;
    
    void main() {
        vUv = uv;
        gl_Position = vec4(position,1.0);
    }
`;



export class ShadertoyTexture {
  
  texture: THREE.Texture;
  image: BufferShader;

  bufferA: BufferShader;
  bufferB: BufferShader;
  bufferC: BufferShader;
  bufferD: BufferShader;

  uniforms : UniformMap = {
    iMouse: {value: new THREE.Vector4(0.5, 0.5, 0, 0)},
    iFrame: {value: 0},
    iTime: {value: 0},
    iResolution: {value: new THREE.Vector2(512, 512)},
  };

  clock = new THREE.Clock();

  set common(value: string) {
    this.bufferA.common = value;
    this.bufferB.common = value;
    this.bufferC.common = value;
    this.bufferD.common = value;
    this.image.common = value;
  }

  constructor(public width: number, public height: number) {
  
    this.bufferA = new BufferShader(this.width, this.height);
    this.bufferB = new BufferShader(this.width, this.height);
    this.bufferC = new BufferShader(this.width, this.height);
    this.bufferD = new BufferShader(this.width, this.height);

    this.image = new BufferShader(this.width, this.height);

    this.texture = this.image.outputBuffer.texture;

    this.render();
  }

  render() {
    this.uniforms.iTime.value = this.clock.getElapsedTime();
    this.uniforms.iFrame.value++;

    const renderOrder = [this.bufferA, this.bufferB, this.bufferC, this.bufferD, this.image];
    renderOrder.forEach((buffer) => {
      if (buffer.enabled) {
        // Update buffer uniforms with global uniforms:
        Object.keys(this.uniforms).forEach((key) => {
          buffer.uniforms[key] = this.uniforms[key];
        });
        buffer.render();
      }
    });
    this.image.outputBuffer.texture.needsUpdate = true;
    // console.timeEnd('render');

  }
  
}

interface IChannelConfig {
  inputBufferShader: BufferShader | undefined;
}

class BufferShader {
  common = '';
  outputBuffer: THREE.WebGLRenderTarget;
  readBuffer: THREE.WebGLRenderTarget;

  private iChannel0Config : IChannelConfig | undefined;
  private iChannel1Config : IChannelConfig | undefined;

  set iChannel0(value: BufferShader) {
    this.iChannel0Config = {
      inputBufferShader: value
    }
  }

  set iChannel1(value: BufferShader) {
    this.iChannel1Config = {
      inputBufferShader: value
    }
  }

  uniforms = {
    iChannel0: {value: new THREE.Texture()},
    iChannel1: {value: new THREE.Texture()}
  };

  camera = new THREE.OrthographicCamera(-1, 1, 1, -1, -1, 1);

  scene: THREE.Scene;

  enabled = false;
  plane: THREE.Mesh<THREE.PlaneGeometry, THREE.Material | THREE.Material[]>;

  set shader(value: string) {
    this.enabled = true;
    const fragmentShader = BASE_FRAG + "\n" + this.common + "\n" + value;

    const material = new THREE.ShaderMaterial({
      fragmentShader,
      vertexShader: VERTEX_SHADER,
      uniforms: this.uniforms
    });

    this.plane.material = material;
  }

  constructor(public width: number, public height: number) {

    this.outputBuffer = new THREE.WebGLRenderTarget(width, height, {
      minFilter: THREE.LinearFilter,
      magFilter: THREE.LinearFilter,
      format: THREE.RGBAFormat,
      encoding: THREE.LinearEncoding,
      type: THREE.FloatType,
      stencilBuffer: false,
      //samples: 2,
    });

    this.readBuffer = this.outputBuffer.clone();

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0x000000);

    this.plane = new THREE.Mesh(new THREE.PlaneGeometry(2, 2), new THREE.MeshBasicMaterial({color: 0xff0000}));
    this.scene.add(this.plane);
  }

  swap() {
    const temp = this.readBuffer;
    this.readBuffer = this.outputBuffer;
    this.outputBuffer = temp;
  }

  render() {
    // Update iChannels:
    if (this.iChannel0Config?.inputBufferShader) {
      const inputBuffer = this.iChannel0Config.inputBufferShader.readBuffer;
      this.uniforms.iChannel0.value = inputBuffer.texture;
    }
    // Update iChannels:
    if (this.iChannel1Config?.inputBufferShader) {
      const inputBuffer = this.iChannel1Config.inputBufferShader.readBuffer;
      this.uniforms.iChannel1.value = inputBuffer.texture;
    }
    renderer.setRenderTarget(this.outputBuffer);
    renderer.clear();
    renderer.render(this.scene, this.camera);
    renderer.setRenderTarget(null);
    this.swap();
  }
}
import { GameObject } from "../core/GameObject";
import { Label } from "./Label";
import { Rocket } from "./Rocket/Rocket";
import { Landable } from "./mixins/Landable";
import * as THREE from "three";

export class LandingPlatform extends GameObject.with(Landable) {
  constructor(label: string = ""){
    super();
    const landingPlatform = new THREE.Mesh(
      new THREE.CylinderGeometry(.5, .5, .2, 12),
      new THREE.MeshPhongMaterial({ color: 0x666666 })
    );
    landingPlatform.rotation.x = Math.PI / 2;
    this.add(landingPlatform);

    if(label) {
      const labelObj = new Label(label);
      labelObj.hideWhenNear = true;
      this.add(labelObj);
      labelObj.position.z -= .5;
    }
  }

  checkLandingAngle(landerPosition: THREE.Vector3, rocketAngle : number) {
    // For landing on a platform, the angle we want is perpendicular to the platform:
    const targetAngle = this.rotation.y + Math.PI/2;
    // Get the difference between the two angles, accounting for the fact that the angle can wrap around
    // EG 0 and 2PI are the same angle, so the difference between them is 0, not 2PI:
    let angleDiff = rocketAngle - targetAngle;
    angleDiff = THREE.MathUtils.euclideanModulo(angleDiff + Math.PI, Math.PI * 2) - Math.PI;
    return Math.abs(angleDiff) < Rocket.MAX_LANDING_ANGLE;
  }
}
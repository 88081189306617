// Particle based confetti effect:

import * as THREE from "three";
import { GameObject } from "../../core/GameObject";
import { gui } from "../../core/setup";
import fragmentShader from "./confetti.frag";
import vertexShader from "./confetti.vert";


const confettiMaterial = new THREE.ShaderMaterial({
  uniforms: {
    time: { value: 0.0 },
  },
  vertexShader,
  fragmentShader,
  visible: false,
  depthWrite: false,
  blending: THREE.AdditiveBlending,
  vertexColors: true
});


export class Confetti extends GameObject {
  confettiCount = 10000;
  animating = false;

  constructor() {
    super();
    this.classList.push("confetti");

    const geometry = new THREE.BufferGeometry();


    const colors = new Float32Array(this.confettiCount * 3);
    const vertices = new Float32Array(this.confettiCount * 3);

    for (let i = 0; i < this.confettiCount * 3; i+= 3) {
      vertices[i] = (Math.random() - .5) * 20;
      vertices[i+1] = Math.random();
      vertices[i + 2] = 0;
      
      colors[i] = Math.random();
      colors[i+1] = Math.random();
      colors[i+2] = Math.random();
    }
    geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3))
    geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));

    gui.add(this, "shoot").name("Shoot Confetti");

    const particles = new THREE.Points(geometry, confettiMaterial);

    this.add(particles);
    // Add a lil box to indicate confetti position:

  };

  shoot() {
    confettiMaterial.visible = true;
    confettiMaterial.uniforms.time.value = 0;

    const animate = () => {
      this.animating = true;
      if(confettiMaterial.uniforms.time.value < 60) {
        confettiMaterial.uniforms.time.value += 0.25;
        requestAnimationFrame(animate);
      } else {
        confettiMaterial.visible = false;
        this.animating = false;
      }
    }
    if(!this.animating) animate();
  }
}


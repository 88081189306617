import * as THREE from 'three';

// A complete reimplementaion of Vector2, except it adds an "onChange" event,
// which is used to then update the Vector3 position of GameObjects...

export class Coordinates extends THREE.Vector2 {
  private changeListener = (coords: Coordinates) => {};

  private _x = 0;
  private _y = 0;
  private _offset = 0;

  // Offset is used for the Z dimension of the GameObject:
  public offset = 0;

  constructor(x: number = 0, y: number = 0) {
    super(x, y);
    this._x = x;
    this._y = y;
    this._offset = 0;

    // Get all methods of Vector2:
    const methods = Object.getOwnPropertyNames( Object.getPrototypeOf(this));

    // For each method, add an event listener to it:
    for(const method of methods){
      // Exclude the constructor:
      if(method === "constructor") continue;
      const original = this[method];
      this[method] = (...args: any[]) => {
        const result = original.apply(this, args);
        this.changeListener(this);
        return result;
      }
    }

    // Additionally, add accessors to the x, y, and offset properties:
    Object.defineProperty(this, "x", {
      get: () => this._x,
      set: (value) => {
        this._x = value;
        this.changeListener(this);
      }
    });
    Object.defineProperty(this, "y", {
      get: () => this._y,
      set: (value) => {
        this._y = value;
        this.changeListener(this);
      }
    });
    Object.defineProperty(this, "offset", {
      get: () => this._offset,
      set: (value) => {
        this._offset = value;
        this.changeListener(this);
      }
    });
  }

  set(x: number, y: number, z? : number) {
    this._x = x;
    this._y = y;
    if(z !== undefined) this._offset = z;
    this.changeListener(this);
    return this;
  }
  toVector3() {
    return new THREE.Vector3(this.x, this.offset, this.y);
  }

  fromVector3(vector: THREE.Vector3) {
    this.x = vector.x;
    this.y = vector.z;
    this.offset = vector.y;
  }

  onChange(listener: (coords: Coordinates) => void) {
    this.changeListener = listener;
  }

  toString() {
    return `coords(${Math.round(this.x * 10) * .1}, ${Math.round(this.y * 10) * .1}, ${Math.round(this.offset * 10) * .1})`;
  }
}

import { Rocket } from "../objects/Rocket/Rocket";
import { camera } from "./setup";
import * as THREE from "three";
import { getStars } from "../objects/Stars";

// Camera lazily follows rocket:
export const beforeRender = () => {
  const rocket = Rocket.getRocket();
  const rocketPos = new THREE.Vector3();
  rocket.getWorldPosition(rocketPos);
  rocketPos.z += 1;

  camera.position.x = rocketPos.x;
  camera.position.y = rocketPos.y + 2;
  camera.position.z = rocketPos.z;
  camera.lookAt(rocketPos);

  // Stars move slightly offset for parallax effect:
  const stars = getStars();
  stars.position.x = rocketPos.x * .4;
  stars.position.z = rocketPos.z * .4;
};
